<template>
  <div class="container">
    <form @submit.prevent="save" v-shortkey="shortkeys" @shortkey="toggleShortkey">
      <div class="d-flex justify-content-start mb-2">
        <page-title>Demographics</page-title>
        <icon-button
          data-testid="matchPatients"
          type="button"
          @click="openMatchPatients"
          v-tooltip="'Click to open patient matcher.'"
          class="btn text-primary"
          icon="link"
          :disabled="hasManuallyMatched"
        />
        <modal @close="isMatching = false" :status="isMatching">
          <patient-matching-popup @setPatient="setPatient" :matchedPatients="matchOptions" />
        </modal>
      </div>
      <div class="">
        <div class="row d-flex mb-2">
          <div class="col-xl-4">
            <div class="row">
              <div class="col-lg-6 d-flex justify-content-between">
                <text-input
                  v-focus
                  class="w-100"
                  label="Order #"
                  name="orderNumber"
                  id="orderNumber"
                  v-model="$v.caseDetails.$model.orderNumber"
                  :validator="$v.caseDetails.orderNumber"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="31"
                />
              </div>
              <div class="col-md-6 d-flex justify-content-between">
                <ssn-input
                  class="w-100"
                  ref="ssn"
                  name="SSN"
                  id="SSN"
                  @keydown.native.space.prevent
                  v-model="$v.caseDetails.$model.patientSSN"
                  :validator="$v.caseDetails.patientSSN"
                  :validatorMsgMap="validatorMsgMap"
                  accessKey="n"
                  @blur="handleChangeMatchingData('patientSSN')"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="row">
              <div class="col-lg d-flex justify-content-between">
                <text-input
                  class="w-100"
                  ref="mrn"
                  id="mrn"
                  name="mrn"
                  v-stream:blur="blurField$"
                  label="MRN"
                  v-model="$v.caseDetails.$model.patientMRN"
                  :validator="$v.caseDetails.patientMRN"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="31"
                  data-private="redact"
                  accessKey="m"
                  @blur="handleChangeMatchingData('patientMRN')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex flex-wrap align-items-start mb-2">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-lg-4 d-flex justify-content-between">
                <text-input
                  id="lastName"
                  ref="lastName"
                  class="w-85"
                  name="lastName"
                  label="Last Name"
                  v-stream:blur="blurField$"
                  v-model="$v.caseDetails.$model.patientLastName"
                  :validator="$v.caseDetails.patientLastName"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="41"
                  data-private="redact"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <text-input
                  id="firstName"
                  class="w-85"
                  name="firstName"
                  ref="firstName"
                  label="First Name"
                  v-stream:blur="blurField$"
                  v-model="$v.caseDetails.$model.patientFirstName"
                  :validator="$v.caseDetails.patientFirstName"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="41"
                  data-private="redact"
                  accessKey="f"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <text-input
                  class="w-85"
                  label="Middle Name"
                  name="patientMiddleName"
                  v-model="$v.caseDetails.$model.patientMiddleName"
                  :validator="$v.caseDetails.patientMiddleName"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="41"
                  data-private="redact"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-lg-4 d-flex justify-content-between">
                <select-input
                  class="w-85"
                  name="patientTitle"
                  v-model="$v.caseDetails.$model.patientTitle"
                  :items="titles"
                  label="title"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <text-input
                  name="maidenName"
                  label="Maiden Name"
                  class="w-85"
                  v-model="$v.caseDetails.$model.patientMaidenName"
                  :validator="$v.caseDetails.patientMaidenName"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="41"
                  data-private="redact"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <select-input
                  name="patientSuffix"
                  class="w-85"
                  v-model="$v.caseDetails.$model.patientSuffix"
                  :items="suffixes"
                  label="Suffix"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex flex-wrap align-items-start mb-2">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-lg-4 d-flex justify-content-between">
                <date-picker
                  id="dob"
                  ref="dateOfBirth"
                  :twoDigitYear="true"
                  label="Date of Birth"
                  class="w-85"
                  dateSerializationFormat="yyyy-MM-dd"
                  v-model="$v.caseDetails.$model.patientDOB"
                  :max="today"
                  name="dob"
                  :validator="$v.caseDetails.patientDOB"
                  :validatorMsgMap="validatorMsgMap"
                  data-private="redact"
                  accessKey="d"
                  @blur="handleChangeMatchingData('patientDOB')"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <select-input
                  class="w-85"
                  name="patientRace"
                  v-model="$v.caseDetails.$model.patientRace"
                  :items="races"
                  label="Race"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <gender-selector
                  class="w-85"
                  v-model="caseDetails.patientSex"
                  accessKey="g"
                  ref="patientSex"
                  :validator="$v.caseDetails.patientSex"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex flex-wrap align-items-start mb-2">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-lg-4 d-flex justify-content-between">
                <select-input
                  id="priority"
                  class="w-85"
                  name="priority"
                  label="Priority"
                  ref="priority"
                  v-model="$v.caseDetails.$model.priority"
                  :items="priorities"
                  :validator="$v.caseDetails.priority"
                  accessKey="y"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <date-picker
                  ref="receivedOn"
                  id="receivedOn"
                  class="w-85"
                  :min="labSettings.AllowReceivedBeforeCollected ? null : caseDetails.collectedOn"
                  :max="maxFutureDays"
                  name="receivedOn"
                  placeholder="MM/DD/YYYY"
                  dateSerializationFormat="yyyy-MM-dd"
                  v-model="$v.caseDetails.$model.receivedOn"
                  :validator="$v.caseDetails.receivedOn"
                  :validatorMsgMap="validatorMsgMap"
                  label="Received"
                  accessKey="e"
                ></date-picker>
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <date-picker
                  ref="collectedOn"
                  id="collected"
                  class="w-85"
                  name="collectedOn"
                  placeholder="MM/DD/YYYY"
                  :min="minCollected"
                  dateSerializationFormat="yyyy-MM-dd"
                  :max="labSettings.AllowReceivedBeforeCollected ? null : caseDetails.receivedOn"
                  v-model="$v.caseDetails.$model.collectedOn"
                  :validator="$v.caseDetails.collectedOn"
                  :validatorMsgMap="validatorMsgMap"
                  label="Collected"
                  accessKey="e"
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-lg-4 justify-content-between d-flex">
                <text-input
                  class="w-85"
                  label="Account #"
                  name="accountNumber"
                  id="accountNumber"
                  ref="accountNumber"
                  accessKey="a"
                  v-model="$v.caseDetails.$model.acctNumber"
                  :validator="$v.caseDetails.acctNumber"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="31"
                  data-private="redact"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <text-input
                  label="Ref #"
                  type="text"
                  class="w-85"
                  name="refNumber"
                  id="refNumber"
                  ref="refNumber"
                  accessKey="r"
                  v-model="$v.caseDetails.$model.refNumber"
                  :validator="$v.caseDetails.refNumber"
                  :validatorMsgMap="validatorMsgMap"
                  maxLength="31"
                  data-private="redact"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <text-input
                  label="Alternate ID"
                  type="text"
                  class="w-85"
                  name="alternateId"
                  id="alternateId"
                  maxLength="31"
                  ref="altId"
                  accessKey="i"
                  v-model="$v.caseDetails.$model.alternateId"
                  :validator="$v.caseDetails.alternateId"
                  :validatorMsgMap="validatorMsgMap"
                  data-private="redact"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex flex-wrap align-items-start mb-2">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-lg-4 d-flex justify-content-between">
                <text-input
                  v-model="$v.caseDetails.$model.roomNumber"
                  label="Room #"
                  name="roomNumber"
                  maxLength="10"
                  :validator="$v.caseDetails.roomNumber"
                  :validatorMsgMap="validatorMsgMap"
                  class="w-85"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <select-input
                  class="w-85"
                  label="Billing Cycle"
                  name="billingCycle"
                  id="billingCylce"
                  :dataSource="billingCyclesSorted"
                  v-model="$v.caseDetails.$model.billingCycle"
                />
              </div>
              <div class="col-lg-4 d-flex justify-content-between">
                <select-input
                  class="w-85"
                  :items="billingTypes"
                  label="Billing Type"
                  name="billingType"
                  id="billingType"
                  v-model="$v.caseDetails.$model.billingType"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-6 flex-column d-flex align-items-baseline pl-4">
            <div class="col">
              <checkbox
                class="form-check-input"
                type="checkbox"
                name="private"
                v-model="$v.caseDetails.$model.private"
                id="private"
                label="Private"
              />
              <checkbox
                class="form-check-input"
                type="checkbox"
                name="reviewed"
                v-model="$v.caseDetails.$model.reviewed"
                id="reviewed"
                label="Reviewed"
              />
            </div>
          </div>
        </div>
        <div class="row border-bottom pb-5">
          <div class="col-xl-6 d-flex flex-column justify-content-start">
            <contact
              :validator="$v.caseDetails.contacts"
              id="provider"
              name="provider"
              ref="provider"
              v-model="$v.caseDetails.$model.contacts"
              :tabProp="2"
              label="Providers"
              accessKey="p"
            />
          </div>
          <div class="col-xl-6 d-flex flex-column justify-content-start">
            <pathologist
              :validator="$v.caseDetails.pathologists"
              id="pathologist"
              ref="pathologist"
              name="pathologist"
              :disabled="!isPathologistEditable"
              v-model="$v.caseDetails.$model.pathologists"
              :tabProp="2"
              label="Pathologists"
              accessKey="p"
            />
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="col-2 form-check"></div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row d-flex">
          <div class="container col">
            <Address
              ref="addresses"
              :v="$v.caseDetails.addresses"
              class="w-full"
              v-model="$v.caseDetails.$model.addresses"
              :tabProp="4"
              :allowEmpty="true"
            />
          </div>
          <div class="container col">
            <Phones
              ref="phoneNumbers"
              :v="$v.caseDetails.phoneNumbers"
              v-model="$v.caseDetails.$model.phoneNumbers"
              :tabProp="4"
            />
            <Emails
              ref="emails"
              :v="$v.caseDetails.emails"
              v-model="$v.caseDetails.$model.emails"
              :tabProp="4"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <loader class="align-self-center mx-2" size="small" v-if="isSaving" />
        <button :disabled="!isSaveEnabled" type="submit" class="btn btn-primary">Save</button>
      </div>
    </form>
    <modal :status="isMismatched" @close="isMismatched = false" class="p-2">
      <h4>Some patient data may not match. Would you like to continue?</h4>
      <prop-table
        :columns="mismatchColumns"
        v-model="mismatchedFieldData"
        :hasActions="false"
        class="my-3 ml-3"
      />
      <h4></h4>
      <div class="d-flex">
        <button type="button" @click="isMismatched = false" class="btn btn-danger ml-auto">
          No
        </button>
        <button
          v-focus
          type="button"
          class="btn btn-success ml-2"
          @click.prevent="fillMissingFields(loadedOrder)"
        >
          Yes
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import Address from "@/components/forms/Address";
import Phones from "@/components/forms/Phones.vue";
import Emails from "@/components/forms/Emails.vue";
import { required, email, maxLength, minLength, numeric, helpers } from "vuelidate/lib/validators";
import moment from "moment";
import {
  createPatientMatchPayload,
  fixProviderPayload,
  getAltKeys,
  isModalOpen,
  removeTimeFromDatetime,
  validatorMsgMapBase
} from "@/modules/helpers";
import { mapState, mapGetters } from "vuex";
import {
  MaxDiffCollectedAndReceived,
  zipCode,
  SSNValidator,
  maxFutureDaysForReceived
} from "@/modules/helpers.js";
import { Contact, Pathologist } from "../Selectors";
import { CasesApi, DropdownApi, Hl7OrdersApi } from "@/services";
import SelectInput from "@/components/common/SelectInput.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import TextInput from "@/components/common/TextInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import Modal from "@/components/common/Modal.vue";
import PatientMatchingPopup from "@/components/PatientMatchingPopup.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Loader from "@/components/common/Loader.vue";
import dialog from "@/modules/dialog";
import IconButton from "@/components/common/IconButton.vue";
import physicianPopup from "@/mixins/physicianPopup.js";
import { CaseEditTypeEnum, CaseStatusEnum, DateFormats } from "@/modules/enums";
import DataSource from "devextreme/data/data_source";
import eventBus, {
  ENTER_ORDER_DEMO,
  LOAD_ORDERS,
  SAVE_FROM_CASE_HEADER,
  SCAN_IN_CASE,
  UPDATE_CASE_DEMOGRAPHICS
} from "@/modules/eventBus";
import handleLoadOrder from "@/modules/handleLoadOrder";
import PropTable from "@/components/common/PropTable.vue";
import GenderSelector from "@/components/forms/Selectors/GenderSelector.vue";
import SsnInput from "@/components/common/SsnInput.vue";
import { sortBy } from "lodash";

export default {
  name: "EditDemographicsForm",
  props: {
    accountNumber: Number,
    isExpanded: Boolean,
    value: Object,
    caseDisplay: String,
    validators: Object
  },
  mixins: [physicianPopup],
  components: {
    Address,
    Phones,
    Emails,
    Pathologist,
    Contact,
    SelectInput,
    DatePicker,
    TextInput,
    Checkbox,
    Modal,
    PatientMatchingPopup,
    Loader,
    PageTitle,
    IconButton,
    PropTable,
    GenderSelector,
    SsnInput
  },
  domStreams: ["blurField$"],
  created() {
    this.$store.dispatch("dropdowns/getCasePhoneTypes");
    this.$store.dispatch("dropdowns/getSexes");
    this.$store.dispatch("dropdowns/getRaces");
    this.$store.dispatch("dropdowns/getSuffixes");
    DropdownApi.getBillingCycles().then(res => {
      this.billingCycles = res || [];
    });
    this.$store.dispatch("dropdowns/getBillingTypes");
    this.$store.dispatch("dropdowns/getPriorities");
    this.$store.dispatch("dropdowns/getTitles");
  },
  mounted() {
    eventBus.$on(SCAN_IN_CASE, barcode => {
      this.barcodeScan = barcode;
      if (document.activeElement.value?.includes(barcode)) {
        this.caseDetails[document.activeElement.id] = this.caseDetails[
          document.activeElement.name
        ].replace(barcode, "");
      }
    });
    eventBus.$on(LOAD_ORDERS, order => {
      this.handleOrderMatch(order);
    });
    eventBus.$on(UPDATE_CASE_DEMOGRAPHICS, () => this.updateCaseState());
    eventBus.$on(SAVE_FROM_CASE_HEADER, () => this.save());
  },
  beforeDestroy() {
    eventBus.$off(SCAN_IN_CASE);
    eventBus.$off(LOAD_ORDERS);
    eventBus.$off(UPDATE_CASE_DEMOGRAPHICS);
    eventBus.$off(SAVE_FROM_CASE_HEADER);
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      token: state => state.token,
      labSettings: state => state.labSettings,
      accessionStoreLoading: state => state.accessionStore.loading,
      currentCase: state => state.accessionStore.caseDetails,
      editType: state => state.accessionStore.editType,
      sexes: state => state.dropdowns.sexes,
      races: state => state.dropdowns.races,
      titles: state => state.dropdowns.titles,
      suffixes: state => state.dropdowns.suffixes,
      phoneTypes: state => state.dropdowns.casePhoneTypes,
      billingTypes: state => state.dropdowns.billingTypes,
      priorities: state => state.dropdowns.priorities,
      casePrefix: state => state.accessionStore.casePrefix
    }),
    ...mapGetters("accessionStore", ["isSigned", "isCaseEditable", "primaryProvider"]),
    ...mapGetters(["permissions"]),
    ...mapGetters("report", ["isInline"]),
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase
      };
    },
    billingCyclesSorted() {
      return new DataSource({
        store: this.billingCycles || [],
        sort: ["displayName"]
      });
    },
    isPathologistEditable() {
      if (this.editType) {
        return ![CaseEditTypeEnum.NonDiagnostic, CaseEditTypeEnum.Billing].includes(this.editType);
      }
      return true;
    },
    minCollected() {
      return moment(this.caseDetails.receivedOn, DateFormats)
        .subtract(this.labSettings.MaxDiffCollectedAndReceivedDates, "days")
        .format("yyyy/MM/DD");
    },
    isSaveEnabled() {
      if (
        this.isSaving ||
        (!this.isCaseEditable && this.caseDetails.status !== CaseStatusEnum.ReportedPrelim)
      ) {
        return false;
      }
      return true;
    },
    maxFutureDays() {
      const {
        groups: { year, month, day }
      } = /^(?<year>[0-9]{4})[-/](?<month>[0-9]{2})[-/](?<day>[0-9]{2})/.exec(
        this.caseDetails.accessionedOn
      );
      const accessionDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      if (this.labSettings?.MaxFutureDaysForReceivedDate) {
        const maxDay = new Date();
        maxDay.setDate(accessionDate.getDate() + this.labSettings.MaxFutureDaysForReceivedDate + 1);
        return maxDay;
      }
      return accessionDate;
    }
  },

  async beforeRouteLeave(to, from, next) {
    if (this.routeLeaveCalled) {
      return;
    }
    this.routeLeaveCalled = true;
    const fieldsClear = await this.checkUnsavedFields();
    if (!fieldsClear) {
      this.routeLeaveCalled = false;
      return;
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.routeLeaveCalled) {
      return;
    }
    this.routeLeaveCalled = true;
    const fieldsClear = await this.checkUnsavedFields();
    if (!fieldsClear) {
      this.routeLeaveCalled = false;
      return;
    }
    next();
  },
  data() {
    return {
      isMatching: false,
      matchedPatients: CasesApi.patientMatch,
      pathologists: [],
      originalCase: {},
      today: new Date(),
      isSaving: false,
      billingCycles: [],
      caseDetails: {},
      shortkeys: getAltKeys("acdefgilmnoprsy"),
      barcodeScan: null,
      routeLeaveCalled: false,
      loadedOrder: {},
      isMismatched: false,
      mismatchedFieldData: [],
      mismatchColumns: [
        { dataField: "propertyName", caption: "Property", width: "auto" },
        { dataField: "case", caption: "Case", width: "auto" },
        { dataField: "order", caption: "Order", width: "auto" }
      ],
      orderSpecimensToAdd: [],
      hasManuallyMatched: false,
      matchOptions: []
    };
  },
  validations() {
    return {
      caseDetails: {
        labPrefix: {
          required
        },
        priority: {
          required
        },
        orderNumber: {
          validOrderNumber: value => {
            // Must only be letters, numbers, or hyphens.
            return value ? /^[a-z0-9-]+$/i.test(value) : true;
          },
          maxLength: maxLength(30)
        },
        patientDOB: {
          maxDateOfBirth: value => {
            if (value) {
              return moment(value, DateFormats).isBefore(moment(new Date()));
            }
            return true;
          },
          DobBeforeCollected: value => {
            if (value && this.caseDetails.collectedOn) {
              return moment(value, DateFormats).isSameOrBefore(
                moment(this.caseDetails.collectedOn)
              );
            }
            return true;
          }
        },
        patientMRN: {
          maxLength: maxLength(30)
        },
        patientSSN: {
          maxLength: maxLength(12),
          minLength: minLength(9),
          SSNValidator() {
            if (this.permissions.SocialSecurityNumberView) {
              return SSNValidator;
            }
            return true;
          }
        },
        numberOfSpecimens: {
          numeric,
          required,
          maxLength: maxLength(3)
        },
        contacts: {
          required,
          onePrimary: function (value) {
            if (value != undefined && value?.length) {
              return value.some(e => e.isPrimary);
            }
            return true;
          }
        },
        receivedOn: {
          required,
          maxFutureDaysForReceived: value => {
            const {
              groups: { year, month, day }
            } = /^(?<year>[0-9]{4})[-/](?<month>[0-9]{2})[-/](?<day>[0-9]{2})/.exec(
              this.caseDetails.accessionedOn
            );
            const accessionDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
            return maxFutureDaysForReceived(
              value,
              this.labSettings?.MaxFutureDaysForReceivedDate,
              accessionDate
            );
          },
          collectedBeforeReceived: value => {
            const { collectedOn, receivedOn } = this.caseDetails;
            if (
              !this.labSettings.AllowReceivedBeforeCollected &&
              value &&
              collectedOn &&
              receivedOn
            ) {
              return receivedOn >= collectedOn;
            }
            return true;
          }
        },
        patientFirstName: {
          required,
          maxLength: maxLength(40)
        },
        patientLastName: {
          maxLength: maxLength(40),
          required
        },
        collectedOn: {
          required: vm => {
            return !this.casePrefix?.collectionDateOptionalAtAccessioning ? helpers.req(vm) : true;
          },
          MaxDiffCollectedAndReceived: value =>
            MaxDiffCollectedAndReceived(
              value,
              this.labSettings?.MaxDiffCollectedAndReceivedDates,
              this.caseDetails.receivedOn
            ),
          CollectedAfterDob: value => {
            if (value && this.caseDetails.patientDOB) {
              return moment(value, DateFormats).isSameOrAfter(moment(this.caseDetails.patientDOB));
            }
            return true;
          }
        },
        emails: {
          $each: {
            email: { required, email }
          }
        },
        phoneNumbers: {
          $each: {
            phoneNumber: { maxLength: maxLength(16) }
          }
        },
        addresses: {
          $each: {
            line1: { required, maxLength: maxLength(100) },
            line2: { maxLength: maxLength(100) },
            state: { required, maxLength: maxLength(100) },
            city: { required, maxLength: maxLength(100) },
            zipCode: { required, zipCode, maxLength: maxLength(10) }
          }
        },
        patientMiddleName: {
          maxLength: maxLength(40)
        },
        patientMaidenName: {
          maxLength: maxLength(40)
        },
        roomNumber: {
          maxLength: maxLength(9)
        },
        acctNumber: {
          maxLength: maxLength(30)
        },
        refNumber: {
          maxLength: maxLength(30)
        },
        patientAlternateId: {
          maxLength: maxLength(30)
        },
        patientSex: {
          required: value =>
            this.labSettings.GenderRequiredAtAccession ? helpers.req(value) : true
        }
      }
    };
  },
  watch: {
    "caseDetails.pathologists": {
      deep: true,
      handler(value) {
        if (value) {
          const anyPrimary = value.filter(e => e.isPrimary);
          if ((!anyPrimary.length && value.length) || anyPrimary.length > 1) {
            this.setPrimaryPathologist(value[0].id);
          }
        }
      }
    },
    currentCase: {
      immediate: true,
      handler(value) {
        if (value.caseId !== this.caseDetails.caseId) {
          this.caseDetails = cloneDeep(this.currentCase);
        }
      }
    },
    "caseDetails.patientMiddleName": function (value) {
      const { ForceUpperCasePatientName } = this.labSettings;
      if (ForceUpperCasePatientName) {
        if (parseInt(ForceUpperCasePatientName)) {
          this.caseDetails.patientMiddleName = value.toUpperCase();
        }
      }
    },
    "caseDetails.patientMaidenName": function (value) {
      if (value) {
        const { ForceUpperCasePatientName } = this.labSettings;
        if (ForceUpperCasePatientName) {
          if (parseInt(ForceUpperCasePatientName)) {
            this.caseDetails.patientMaidenName = value.toUpperCase();
          }
        }
      }
    },
    "caseDetails.patientLastName": function (value) {
      // const { patientDOB, patientFirstName } = this.caseDetails;
      const { ForceUpperCasePatientName } = this.labSettings;
      if (ForceUpperCasePatientName) {
        if (parseInt(ForceUpperCasePatientName)) {
          this.caseDetails.patientLastName = value.toUpperCase();
        }
      }
    },
    "caseDetails.addresses": {
      deep: true,
      handler(value) {
        const { ForceUpperCaseAddress } = this.labSettings;
        if (ForceUpperCaseAddress && value?.length) {
          let index = null;
          const el = document.activeElement;
          if (el?.selectionStart) {
            index = el.selectionStart;
          }
          value = value.map(entry => {
            for (value in entry) {
              if (parseInt(ForceUpperCaseAddress) && typeof entry[value] === "string") {
                entry[value] = entry[value].toUpperCase();
              }
            }
            return entry;
          });
          this.$nextTick(() => {
            if (index !== null) {
              el.setSelectionRange(index, index);
            }
          });
        }
      }
    },
    "caseDetails.patientFirstName": function (value) {
      const { ForceUpperCasePatientName } = this.labSettings;
      if (ForceUpperCasePatientName) {
        this.caseDetails.patientFirstName = value.toUpperCase();
      }
    },
    "caseDetails.orderNumber"(nv, ov) {
      if (nv === ov) {
        return;
      }
      eventBus.$emit(ENTER_ORDER_DEMO, nv);
    }
  },
  methods: {
    openMatchPatients() {
      this.isMatching = true;
    },
    async setPatient(patientRecord, isAutomatic) {
      if (!isAutomatic) {
        this.hasManuallyMatched = true;
      }
      const skipConfirms = isAutomatic || Boolean(this.matchOptions.length);
      const { firstName, lastName, ssn, sex, mrn, dateOfBirth } = patientRecord;
      if (
        !skipConfirms &&
        this.caseDetails.patientId &&
        this.caseDetails.patientId !== patientRecord.id
      ) {
        const confirm = await window.confirm(
          `This case already has a patient record. <br/> Assigning it to this patient could remove data. <br/> Do you wish to continue? `
        );
        if (!confirm) {
          this.isMatching = false;
          return;
        }
      } else {
        for (const property of [
          ["patientSSN", "ssn"],
          ["patientMRN", "mrn"],
          ["patientSex", "sex"],
          ["patientFirstName", "firstName"],
          ["patientDOB", "lastName"],
          ["phoneNumbers", "phoneNumbers"],
          ["emails", "emails"],
          ["addresses", "address"],
          ["alternateId", "alternateId"],
          ["patientRace", "race"],
          ["suffix", "suffix"]
        ]) {
          if (!patientRecord[property[1]]) {
            continue;
          }
          if (
            JSON.stringify(this.caseDetails[property[0]]) !==
              JSON.stringify(patientRecord[property[1]]) &&
            !skipConfirms
          ) {
            const confirm = window.confirm(
              `Some of your existing data does not match, continuing will overwrite it. <br/> Do you wish to continue?`
            );
            if (!confirm) {
              return;
            }
            break;
          }
        }
      }
      this.caseDetails.patientSSN = ssn;
      this.caseDetails.patientDOB = dateOfBirth;
      this.caseDetails.patientMRN = mrn;
      this.caseDetails.patientSex = sex;
      this.caseDetails.patientFirstName = firstName;
      this.caseDetails.patientLastName = lastName;
      this.caseDetails.alternateId = patientRecord.alternateId;
      this.caseDetails.patientRace = patientRecord.race;
      this.caseDetails.emails = patientRecord.emails;
      this.caseDetails.addresses = patientRecord.address;
      this.caseDetails.phoneNumbers = patientRecord.phoneNumbers;
      this.caseDetails.suffix = patientRecord.suffix || "";
      this.caseDetails.patientId = patientRecord.id;
      this.isMatching = false;
      this.matchOptions = [];
      this.$nextTick(() => {
        if (this.currentActiveElement) {
          this.currentActiveElement.focus();
        }
      });
    },
    toggleShortkey(event) {
      if (isModalOpen()) {
        return;
      }
      switch (event.srcKey) {
        case "p":
          if (!this.$refs.provider.isFocused) {
            this.$refs.provider.focus();
          } else {
            this.$refs.pathologist.focus();
          }
          break;
        case "e":
          if (!this.$refs.collectedOn.isFocused) {
            this.$refs.collectedOn.focus();
          } else {
            this.$refs.receivedOn.focus();
          }
          break;
        case "d":
          this.$refs.dateOfBirth.focus();
          break;
        case "f":
          this.$refs.firstName.focus();
          break;
        case "l":
          this.$refs.lastName.focus();
          break;
        case "n":
          this.$refs.ssn.focus();
          break;
        case "m":
          this.$refs.mrn.focus();
          break;
        case "y":
          this.$refs.priority.focus();
          break;
        case "g":
          this.$refs.patientSex.focus();
          break;
        case "a":
          this.$refs.accountNumber.focus();
          break;
        case "r":
          this.$refs.refNumber.focus();
          break;
        case "i":
          this.$refs.altId.focus();
          break;
        case "s":
          this.save();
          break;
        default:
          break;
      }
    },

    focusInput(id) {
      const element = document.querySelector("#" + id);
      if (element) {
        element?.focus();
        element.scrollIntoView({
          block: "center",
          behavior: "smooth"
        });
      }
    },
    async save() {
      this.isSaving = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$v.$reset();
        try {
          const confirmStatus = await this.getPhysicianPopup(3); //Case Save
          if (!confirmStatus) {
            window.notify("User cancelled action.", "error");
            this.isSaving = false;
            return;
          }
          this.removeEmptyContactInfo();
          this.caseDetails.contacts = fixProviderPayload(this.caseDetails.contacts);
          const updatedCase = await this.$store.dispatch("accessionStore/updateCaseDetails", {
            ...this.caseDetails,
            patientAccountNumber: this.caseDetails.acctNumber
          });
          if (this.orderSpecimensToAdd.length) {
            for (const specimen of this.orderSpecimensToAdd) {
              await this.$store.dispatch("accessionStore/insertCaseSpecimen", specimen);
            }
          }
          if (Object.keys(this.loadedOrder).length) {
            await this.$store.dispatch("accessionStore/getCaseCounts", this.caseDetails.caseId);
            await Hl7OrdersApi.activateOrder(this.loadedOrder.orderId, false);
          }
          const requiredCalls = [
            this.$store.dispatch("accessionStore/getCaseDetails", this.caseDetails.caseId),
            this.$store.dispatch("accessionStore/getCaseSpecimens", this.caseDetails.caseId)
          ];

          /* IP-300 We need to refresh the fields to include what ids got ASSIGNED from the save.*/
          this.caseDetails.contacts = updatedCase.contacts;
          this.caseDetails.pathologists = updatedCase.pathologists;
          this.caseDetails.phoneNumbers = updatedCase.phoneNumbers;
          this.caseDetails.emails = updatedCase.emails;
          this.caseDetails.addresses = updatedCase.addresses;
          await Promise.all(requiredCalls);
          /* IP-300 We need to refresh the fields to include what ids got ASSIGNED from the save.*/
          this.caseDetails.contacts = updatedCase.contacts;
          this.caseDetails.pathologists = updatedCase.pathologists;
          this.caseDetails.phoneNumbers = updatedCase.phoneNumbers;
          this.caseDetails.emails = updatedCase.emails;
          this.caseDetails.addresses = updatedCase.addresses;
          window.notify(`Updated case ${this.caseDetails.caseNumber}.`, "success", 2000, {
            at: "center",
            of: "#navbar"
          });
        } catch (error) {
          window.notify(`Error saving case ${this.caseDetails.caseNumber}.`, "error");
        }
      } else {
        window.notify("Please check your input and try again.", "warning");
      }
      this.isSaving = false;
    },
    setPrimaryPathologist(id) {
      this.caseDetails.pathologists = this.caseDetails.pathologists.map(entry => {
        if (entry?.id === id) {
          entry.isPrimary = true;
        } else {
          entry.isPrimary = false;
        }
        return entry;
      });
    },
    async checkUnsavedFields() {
      const fieldsToCheck = [
        "orderNumber",
        "patientSSN",
        "patientMRN",
        "patientLastName",
        "patientFirstName",
        "patientMiddleName",
        "patientTitle",
        "patientMaidenName",
        "patientSuffix",
        "patientDOB",
        "patientRace",
        "patientSex",
        "priority",
        "acctNumber",
        "refNumber",
        "alternateId",
        "collectedOn",
        "receivedOn",
        "roomNumber",
        "billingCycle",
        "billingType",
        "private",
        "reviewed"
      ];
      const arrayFields = ["contacts", "addresses", "phoneNumbers", "emails"];
      let fieldsChanged = false;
      const barcode = this.barcodeScan;
      for (const field of fieldsToCheck) {
        if (
          barcode &&
          typeof this.caseDetails[field] === "string" &&
          this.caseDetails[field].includes(barcode)
        ) {
          this.caseDetails[field] = this.caseDetails[field].replace(barcode, "");
        }
        if (["patientDOB", "collectedOn", "receivedOn"].includes(field)) {
          if (
            removeTimeFromDatetime(this.caseDetails[field]) !==
            removeTimeFromDatetime(this.caseDetailsState[field])
          ) {
            fieldsChanged = true;
          }
        } else if (field === "patientSSN") {
          if (
            this.caseDetails.patientSSN?.replaceAll(/\D/g, "") !==
            this.caseDetailsState.patientSSN?.replaceAll(/\D/g, "")
          ) {
            fieldsChanged = true;
          }
        } else if (!fieldsChanged && this.caseDetails[field] != this.caseDetailsState[field]) {
          fieldsChanged = true;
        }
      }
      const oldPathologists = sortBy(this.caseDetailsState.pathologists, "id")
        .map(e => e.id + ": " + e.isPrimary)
        .join(", ");
      const newPathologists = sortBy(this.caseDetails.pathologists, "id")
        .map(e => e.id + ": " + e.isPrimary)
        .join(", ");
      if (oldPathologists !== newPathologists) {
        fieldsChanged = true;
      }
      for (const arrayField of arrayFields) {
        if (
          JSON.stringify(this.caseDetails[arrayField]) !==
          JSON.stringify(this.caseDetailsState[arrayField])
        ) {
          fieldsChanged = true;
          break;
        }
      }
      if (fieldsChanged) {
        const confirm = await dialog(
          "You may have unsaved data. Unsaved will be lost if you proceed.<br> Are you sure?",
          "IntelliPath Pro",
          "Yes",
          "No"
        ).show();
        if (!confirm) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    async handleOrderMatch(order) {
      let orderDetails = handleLoadOrder({
        order,
        caseDetails: {},
        specimenNumberingType: this.specimenNumberingType,
        phoneTypes: this.phoneTypes,
        sexes: this.sexes,
        labProtocols: this.labProtocols
      });
      orderDetails = { ...orderDetails, orderId: order.id };
      this.loadedOrder = orderDetails;
      this.mismatchedFieldData = [];
      const caseDetails = this.caseDetails;
      for (const property of [
        "patientFirstName",
        "patientLastName",
        "patientDOB",
        "patientSSN",
        "patientMRN"
      ]) {
        if (
          orderDetails[property] &&
          caseDetails[property] &&
          orderDetails[property].toLowerCase() !== caseDetails[property].toLowerCase()
        ) {
          const propertyNames = {
            patientFirstName: "First Name",
            patientLastName: "Last Name",
            patientSSN: "SSN",
            patientMRN: "MRN",
            patientDOB: "Date of Birth"
          };
          if (property === "patientDOB") {
            caseDetails[property] = moment(caseDetails[property]).format("MM/DD/YYYY");
            orderDetails[property] = moment(orderDetails[property]).format("MM/DD/YYYY");
          }
          this.mismatchedFieldData.push({
            case: caseDetails[property],
            order: orderDetails[property],
            propertyName: propertyNames[property]
          });
        }
      }
      if (this.mismatchedFieldData.length) {
        this.isMismatched = true;
        return;
      }
      this.fillMissingFields(orderDetails);
    },
    fillMissingFields(orderDetails) {
      for (const property in orderDetails) {
        if (this.caseDetails[property] === null || this.caseDetails[property] === "") {
          this.caseDetails[property] = orderDetails[property];
        }
        if (Array.isArray(orderDetails[property]) && !this.caseDetails[property].length) {
          this.caseDetails[property] = orderDetails[property];
          if (property === "specimens") {
            this.orderSpecimensToAdd = orderDetails.specimens;
          }
        }
      }
      this.isMatching = false;
      this.isMismatched = false;
      this.mismatchedFieldData = [];
      this.orderDetails = {};
    },
    removeEmptyContactInfo() {
      if (this.caseDetails.addresses?.length) {
        this.caseDetails.addresses = this.caseDetails.addresses.filter(e => {
          return e.line1 && ((e.city && e.state) || e.zipCode || e.international);
        });
      }
      if (this.caseDetails.emails?.length) {
        this.caseDetails.emails = this.caseDetails.emails.filter(e => {
          return e.address || e.email;
        });
      }
      if (this.caseDetails.phoneNumbers?.length) {
        this.caseDetails.phoneNumbers = this.caseDetails.phoneNumbers.filter(e => {
          return e.phoneNumber;
        });
      }
    },
    handleChangeMatchingData(field) {
      // Only run logic for cases with no history
      if (this.caseDetailsState.hx) {
        return;
      }
      const originalValue = this.caseDetailsState[field];
      const newValue = this.caseDetails[field];
      // Only run if matching value changed was not present in previous save
      if (!originalValue && newValue !== originalValue) {
        this.handleMatchPatient(this.caseDetails);
      }
    },
    async handleMatchPatient(caseDetails) {
      const payload = createPatientMatchPayload(caseDetails);
      let { data } = await CasesApi.matchPatient(payload);
      data = data.filter(e => e.id !== this.caseDetails.patientId);
      if (!data.length) {
        return;
      }
      if (data.length === 1 && data[0].isExact) {
        this.setPatient(data[0], true);
      } else {
        this.isMatching = true;
        this.matchOptions = data;
      }
    },
    updateCaseState() {
      this.caseDetails = cloneDeep(this.currentCase);
    }
  }
};
</script>
<style lang="scss" scoped>
.form-row {
  justify-content: flex-start;
  align-items: center;
}
.form-group {
  /* display: flex; */
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: left;
}
label {
  font-size: 14px;
  margin-bottom: 0;
}
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}
.addresses {
  overflow-y: scroll;
  max-height: 400px;
}

.header {
  background-color: #f2f2f2;
  padding: 0.5rem 0.7rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  text-align: left;
  align-items: center;
}
.phone,
.email,
.address {
  padding: 0.5rem 1.2rem;
  .container {
    background-color: #f2f2f2;
  }
  .form-row {
    align-items: center;
  }
}
::v-deep #prioritylabel > b > u {
  text-decoration: none;
  border-bottom: 1.5px solid black;
}
.phones-main,
.emails-main {
  overflow-y: auto;
  max-height: 170px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
